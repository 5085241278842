import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';

const StyledColumn = styled.div`
  width: ${({ width }) => width || '100%'};
  min-height: ${({ height }) => (height ? height : 'auto')};
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  flex-direction: ${({ flexColumn }) => (flexColumn ? 'column' : null)};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : null};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : null)};

  margin: ${({ margin }) => (margin ? margin : '0 auto')};
  padding: ${({ padding, theme }) => (padding ? padding : theme.spacing.lg)};
`;

const Column = ({ children, ...props }) => {
  return <StyledColumn {...props}>{children}</StyledColumn>;
};

// Column.propTypes = {
//   flex: PropTypes.bool, //Set display value on flex -- default is block
//   flexColumn: PropTypes.bool, //Set flex-direction on column -- default is row
//   justifyContent: PropTypes.string, //Set flex justify content
//   alignItems: PropTypes.string, //Set align items content
//   height: PropTypes.string, //Set height -- default is auto
//   margin: PropTypes.oneOfType([PropTypes.func, PropTypes.string]), //Set margin
//   padding: PropTypes.oneOfType([PropTypes.func, PropTypes.string]), //Set padding
// };

// Column.defaultProps = {
//   flex: false,
//   flexColumn: false,
//   height: 'auto',
// };

export default Column;
