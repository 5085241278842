import React from 'react';
import styled from 'styled-components';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const StyledMyMap = styled.div`
  width: 100%;
  height: 100%;
  .leaflet-container {
    height: 100%;
  }
`;

const MyMap = () => {
  if (typeof window !== 'undefined') {
    const position = [54.20669, 16.18556];
    return (
      <StyledMyMap>
        <MapContainer center={position} zoom={17} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <Marker position={position}>
            <Popup>Jukon Emka</Popup>
          </Marker>
        </MapContainer>
      </StyledMyMap>
    );
  }
  return null;
};

export default MyMap;
