import styled from 'styled-components';

export const StyledHeader = styled.div`
  font-size: 48px;
  display: flex;
  align-items: baseline;
  text-align: center;
  padding: 16px 0;
  ::before,
  ::after {
    content: '';
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #838383;
    margin-right: 25px;
  }
  ::after {
    margin-right: 0;
    margin-left: 25px;
  }
  @media (max-width: ${({theme})=>theme.mediaQueries.mobile}) {
    font-size: 36px;
  }
`;
