import styled from 'styled-components';

const getBackgroundParams = (data) => {
  const myDataArray = [];
  const newData = data.split(',');
  newData.map((item) => {
    let replacedNewData = item.replace('\n', '');
    let splittedNewData = replacedNewData.split(' ');
    return myDataArray.unshift({
      path: splittedNewData[0],
      resolution: splittedNewData[1].replace('w', 'px'),
    });
  });
  let mediaQueries = `background-image: url(${myDataArray[0]['path']});\n`;
  myDataArray.map(({ resolution, path }) => {
    return (mediaQueries += `@media (max-width: ${resolution}) {background-image: url(${path});}\n`);
  });
  return mediaQueries;
};

export const Container = styled.div`
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  flex-direction: ${({ flexDir, flex }) =>
    flexDir !== undefined && flex ? flexDir : null};
  justify-content: ${({ flex, justifyContent }) =>
    flex ? (justifyContent ? justifyContent : 'center') : null};
  align-items: ${({ flex, alignItems }) =>
    flex ? (alignItems ? alignItems : 'center') : null};
  width: ${({ fluid, theme }) => (fluid ? '100%' : theme.media.lg)};

  ${({ maxHeight, height }) =>
    maxHeight
      ? `height: ${maxHeight};`
      : `min-height: ${height ? height : 'auto'};`}

  margin: ${({ margin }) => (margin ? margin : '0 auto')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  gap: ${({ gap }) => (gap ? gap : null)};
  /* Background params */
  ${({ bgImage }) => (bgImage ? getBackgroundParams(bgImage) : null)}
  ${({ bgImage, bgSize }) =>
    bgImage
      ? bgSize
        ? `background-size: ${bgSize};`
        : `background-size: cover;`
      : null}
  ${({ bgImage, bgPos }) =>
    bgImage
      ? bgPos
        ? `background-position: ${bgPos};`
        : `background-position: center center ;`
      : null}
  ${({ bgImage, bgAtt }) =>
    bgImage
      ? bgAtt
        ? `background-attachment: ${bgAtt};`
        : `background-attachment: local ;`
      : null}
      ${({ bgImage, bgRep }) =>
    bgImage
      ? bgRep
        ? `background-repeat: ${bgRep};`
        : `background-repeat: no-repeat ;`
      : null}

  background-color: ${({ bgColor }) => (bgColor ? bgColor : null)};
  background-blend-mode: ${({ bgBlend }) => (bgBlend ? bgBlend : null)};
  opacity: ${({ opacity }) => (opacity ? opacity : null)};

  @media (max-width: ${({ theme }) => theme.media.lg}) {
    width: 100%;
  }

  @media (max-width: ${({ flexWrap, theme }) =>
      flexWrap ? flexWrap : theme.media.md}) {
    flex-wrap: wrap;
  }
`;
