import React from 'react';
import Header from '../Header/Header';
import Container from '../Container/Container';
import * as Styles from './Section.styles';

const Section = ({ header, sectionImage, sectionContent, imageAlt, myId }) => {
  return (
    <>
      <Container fluid>
        <Container
          tag='div'
          height='calc(100vh - 80px)'
          style={{ marginTop: '80px' }}
          fluid
          flex
          bgImage={sectionImage.childImageSharp.fluid.srcSet}
          id={myId}>
          {/* <Styles.SectionBackground> */}
          <Styles.SectionBackgroundHeader>
            {header}
          </Styles.SectionBackgroundHeader>
          {/* </Styles.SectionBackground> */}
        </Container>
        <Styles.ContentWrapper>
          <Header size='h2'>{header}</Header>
          <Styles.SectionParagraph>{sectionContent}</Styles.SectionParagraph>
        </Styles.ContentWrapper>
      </Container>
    </>
  );
};

export default Section;
