import React from 'react';
import * as Styles from './Container.Styles';
import PropTypes from 'prop-types';

const Container = ({ children, ...props }) => {
  return (
    <Styles.Container as={props.tag} {...props}>
      {children}
    </Styles.Container>
  );
};

Container.propTypes = {
  flex: PropTypes.bool, //Set display value on flex -- default is block
  fluid: PropTypes.bool, //Set width on 100% screen size -- defult is Theme desktop size
  flexDir: PropTypes.string, //Set flex-direction
  justifyContent: PropTypes.string, //Set flex justify content
  alignItems: PropTypes.string, //Set align items content
  height: PropTypes.string, //Set height -- default is auto
  flexWrap: PropTypes.oneOfType([PropTypes.func, PropTypes.string]), //Set resolution to wrap flex elements -- defualt is set in Theme file
  margin: PropTypes.oneOfType([PropTypes.func, PropTypes.string]), //Set margin
  padding: PropTypes.oneOfType([PropTypes.func, PropTypes.string]), //Set padding
  gap: PropTypes.string, //Set gap
  bgImage: PropTypes.string, //Set background image
  bgPos: PropTypes.string, //Set background position
  bgSize: PropTypes.string, //Set background size
  bgAtt: PropTypes.string, // Set background attachment
  bgRep: PropTypes.string, // Set background repeat
  bgColor: PropTypes.string, // Set background color
  bgBlend: PropTypes.string, // Set background-blend-mode
  opacity: PropTypes.string, // Set opacity
  tag: PropTypes.string, // Set html element to container
};

export default Container;
