import React from 'react';
import { graphql } from 'gatsby';
import Section from '../components/Section/Section';
import Container from '../components/Container/Container';
import MyMap from '../components/Map/Map';
import Column from '../components/Column/Column';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import * as Styles from './../components/Section/Section.styles';

const MyMapColumn = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  @media (max-width: 768px) {
    height: 50vh;
  }
`;
const IndexPage = ({ data }) => {
  const myData = [
    {
      myId: 'garnitury',
      header: 'Garnitury',
      sectionImage: data.Garnitury,
      imageAlt: 'Mężczyzna w garniturze poprawiający krawat',
      // sectionContent:
      //   'Posiadamy w ofercie garnitury męskie, na wyjątkowe okazje jak ślub, ale też do noszenia codziennego. Nasz asortyment produkowany jest przez polskie firmy. Dodatkowo do garnituru mamy masę dodatków takich jak: koszule, krawaty, spinki, muchy buty, paski, płaszcze, swetry. W naszym sklepie służymy fachowym doradztwem w zakresie doboru garnituru oraz dodatków.',
      sectionContent:
        'Posiadamy w ofercie garnitury męskie, koszule, krawaty, spinki, muchy buty, paski, płaszcze, swetry.',
    },
    {
      myId: 'jeansy',
      header: 'Jeansy',
      sectionImage: data.Jeansy,
      imageAlt: 'Mężczyzna dżinsowym ubraniu',
      // sectionContent: 'W naszej ofercie posiadamy jeasnowe ubiory zarówno dla kobiet, jak i dla mężczyzn. Można u nas znaleźć modele o klasycznym kroju oraz jak i te inspirowane najnowszymi trendami modowymi. Szeroki asortyment ubrań sprawia, że każdy jest w stanie znaleźć coś dla siebie na każdą okazję i porę roku. Nasza oferta zaspokoi oczekiwania ludzi aktywnych, preferujących styl casualowy i tych, którzy cenią wygodę. Odzież z naszej oferty łączy cechy użytkowe z estetyką, jakość i łatwość konserwacji  z modnym wozrnictwem. Zaletą o której nie sposób nie wspomnieć jest możliwość dowolnego łączenia ze sobą poszczególnych elementów garderoby.',
      sectionContent:
        'W naszej ofercie posiadamy jeasnowe ubiory zarówno dla kobiet, jak i dla mężczyzn. Nasza oferta zaspokoi oczekiwania ludzi aktywnych, preferujących styl casualowy i tych, którzy cenią wygodę.',
    },
    {
      myId: 'portfele',
      header: 'Portfele',
      sectionImage: data.Portfele,
      imageAlt: 'Mężczyzna chowający portfel do marynarki',
      // sectionContent: 'Oprócz odzieży oferujemy także skórzane portfele damskie i męskie. Mamy szeroki wybór tego jakże często używanego dodatku. Z racji właśnie na częstotliwość użytkowania warto postawić na portfel skórzany, który będzie służył ale i dobrze wyglądał przez długi czas od zakupu. Nasze portfele są eleganckie, trwałe i atrakcyjne cenowo. ',
      sectionContent: 'Oferujemy także skórzane portfele damskie i męskie.',
    },
  ];

  return (
    <>
      {myData.map(
        ({ header, sectionImage, sectionContent, imageAlt, myId }, id) => {
          return (
            <Section
              myId={myId}
              header={header}
              sectionImage={sectionImage}
              imageAlt={imageAlt}
              sectionContent={sectionContent}
              key={id}
            />
          );
        },
      )}
      <Container flexWrap={'768px'} fluid flex>
        <MyMapColumn height={'100vh'} flex alignItems={'center'}>
          <MyMap />
        </MyMapColumn>
        <Column flex alignItems={'center'}>
          <GatsbyImage
            image={data.Mapa.childImageSharp.gatsbyImageData}
            alt='Mapa Emki'
          />
        </Column>
      </Container>
      <Container>
        <Styles.SectionParagraph style={{ marginBottom: '60px' }}>
          Zapraszamy do odwiedzenia naszego sklepu.
        </Styles.SectionParagraph>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    Garnitury: file(name: { eq: "Garnitury" }) {
      childImageSharp {
        fluid(quality: 80, srcSetBreakpoints: [600, 960, 1280, 1600, 1920]) {
          srcSet
        }
      }
    }
    Jeansy: file(name: { eq: "Jeansy" }) {
      childImageSharp {
        fluid(quality: 80, srcSetBreakpoints: [600, 960, 1280, 1600, 1920]) {
          srcSet
        }
      }
    }
    Portfele: file(name: { eq: "Portfele" }) {
      childImageSharp {
        fluid(quality: 80, srcSetBreakpoints: [600, 960, 1280, 1600, 1920]) {
          srcSet
        }
      }
    }
    Mapa: file(name: { eq: "Emka-mapa" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, placeholder: BLURRED)
      }
    }
  }
`;

export default IndexPage;
