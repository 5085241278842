import styled from 'styled-components';

export const SectionBackground = styled.div`
  width: 100%;
  height: 730px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 998px) {
    height: 100vh;
    .gatsby-image-wrapper {
      overflow: auto;
      position: static;
    }
  }
`;

export const SectionBackgroundHeader = styled.div`
  position: absolute;
  font-size: 48px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.black};
  letter-spacing: 0.2rem;
  padding: ${({ theme }) => theme.spacing.lg};
  border: 1px solid ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  box-shadow: 0 0 50px -10px rgba(0, 0, 0, 0.5);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  @media (max-width: ${({ theme }) => theme.mediaQueries.tablet}) {
    font-size: 32px;
    letter-spacing: 0;
  }
`;

export const ContentWrapper = styled.div`
  width: 875px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 60px 16px 80px 16px;
  background-color: #fff;
  @media (max-width: 875px) {
    width: 100%;
  }
`;

export const SectionParagraph = styled.p`
  width: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  margin-top: 60px;
  @media (max-width: 760px) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    margin-top: 30px;
  }
`;
